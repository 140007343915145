<template>
  <th-page-wrapper>
    <th-datatable
      do-route
      do-route-filters
      show-search-filter
      no-meta-check
      sortable
      resource="staffGroups"
      :show-operations="true"
      route-base="/staff/staff_groups"
      :operations="getOperations()"
      :headers="headers"
      :resource-query="{ query: { deleted: false } }"
      :buttons="computedButtons"
      :permissions="permissions"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import qs from 'qs'

export default {
  name: 'StaffGroupsAll',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.staff.staff-groups')
    }
  },
  data() {
    return {
      permissions: {
        create: {
          scopes: ['staff:create']
        },
        update: {
          scopes: ['staff:update']
        },
        delete: {
          scopes: ['staff:delete']
        }
      },
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'description',
          label: this.$t('common.headers.description.title'),
          minWidth: 180,
          truncate: true,
          fallback: '-'
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['staff:groups:create']
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('nav.main.items.staff.staff-groups')
        })
      })
    },
    getOperations() {
      return {
        link: {
          text: this.$t('pages.staff_groups.all.view_staff'),
          disable: (row) => {
            return row && row.active === '0' && row.inactive === '0'
          },
          handleClick: (row) => {
            const query = qs.stringify(
              { filter: { staff_groups: row.id } },
              { addQueryPrefix: true },
              ''
            )
            this.$router.push(`/staff${query}`)
          }
        }
      }
    }
  }
}
</script>

<style scoped></style>
